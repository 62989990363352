import { useZendesk } from '@wrappers/Zendesk'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
//need to pass the initial routing info from zendesk down to next router
export const InitialRouteWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const router = useRouter()
  const { context, settings } = useZendesk()

  useEffect(() => {
    if (context?.appRoute) {
      router.replace(`/hosted/${settings.app_name}/${context.appRoute}`)
    }
  }, [])

  return <>{children}</>
}
