import type { AppName } from '@constants/appName'

export const datadogRumKeys: { [key in AppName]: { applicationId?: string; clientToken?: string } } = {
  'ask-abe': {},
  'batch-manager': {
    applicationId: 'eb3f8944-a34e-4d07-9b8f-f36f44273b2b',
    clientToken: 'pubf64a2a2a4df39ca924afc0cbdb34cbd6',
  },
  'bulk-mailer': {
    applicationId: 'b9bdd085-aef5-456c-9ebd-41e8cf4ea628',
    clientToken: 'pub5161658a27adae8021da38aa25af0945',
  },
  'constituent-profile-merge': {},
  'constituent-snapshot': {},
  'constituent-tag-manager': {},
  'contact-book': {},
  'email-options': {},
  'household-manager': {},
  print: {},
  'print-queue': {
    applicationId: '48100d00-7a27-49aa-84b8-d9340285c4f5',
    clientToken: 'pub9ac81c5bd1fae516b0ea1019a7a4315a',
  },
  'response-library': {
    applicationId: '5164eaa7-21a1-4e87-9867-dcd406000c4f',
    clientToken: 'pub99ae35a2e5052a3f2dc31e0575723286',
  },
  'response-queue': {
    applicationId: '3df85ce6-986e-4683-8d2f-086f86332127',
    clientToken: 'pubef43df1209bfed89bfcd4004b1f02c7b',
  },
  'side-conversations': {},
  'tag-manager': {},
  'ticket-console': {},
  'ticket-event-tracker': {},
  'ticket-logger': {
    applicationId: '6b2fd1f5-27b9-4c32-9cbf-7aa1a53f6b7d',
    clientToken: 'pubb3ba22e0309afd0a3b8258ea52eb85f4',
  },
  //TODO remove old app names once lerna packages are deleted
  'ticket-attachments': {},
  'ticket-reminder': {
    applicationId: '75bd9182-47fa-4ac2-99ca-0a888e6471b0',
    clientToken: 'puba94c47e1f21800da306f533a505c88d7',
  },
  'user-id-search': {},
  //END TODO
  'user-search': {},
  'attachment-viewer': {},
  'reminder-date': {},
  'ticket-side-conversations': {},
  'ticket-editor-side-conversations': {},
  'email-checker': {},
}
