import type { AppName } from '@constants/appName'

export const bugsnagApiKeys: { [key in AppName]: string } = {
  'ask-abe': 'c1352254e057dfdae86bff28deaaf1a1',
  'batch-manager': 'c71fcb221e7f567cc3d79d3f3e4c9639',
  'bulk-mailer': '92be0fa98f1c772c9e959461badfbbda',
  'constituent-profile-merge': '36adff1818bcdf0be67f96fa8f844c4d',
  'constituent-snapshot': '662d17b0bee2b7ca5c03a9381efc99fa',
  'constituent-tag-manager': '34ef9da819aaba0619331d343ad206e6',
  'contact-book': 'a6c1dce14c3f373d08aa82fddd8518ac',
  'email-options': '04b712c0810cea5bced66f5ebb587bab',
  'household-manager': 'dd2efba6df0be5921d5fd79d85ab08ca',
  print: '0f7feffa8db0de2844ba2beaaaf38bc4',
  'print-queue': '48e9919fae6a3d36ca816ae6b1e8f92e',
  'response-library': 'bf6f9a71baab3250dc7c949a0fd12663',
  'response-queue': 'ea4c8ef557b4662df4b819d3d6515c75',
  'side-conversations': 'c4ac548efefb7120ab13a1b893600727',
  'tag-manager': '03e4694ff15ef12e917c42d2e00655c4',
  'ticket-console': '2b02e59abba859f7a4d8c26755413ee8',
  'ticket-event-tracker': '0a75b967e41ccf4690d0226a68f46ed3',
  'ticket-logger': '601337167cad9eda72b2f199f6924977',
  //TODO remove old app names once lerna packages are deleted
  'ticket-attachments': '01193fbf1b87ab079110c3830f702ce7',
  'ticket-reminder': 'fc9da39fe6eb93e664dc6ebbd8128a73',
  'user-id-search': '0f2fed896b93127038648b59da7ba643',
  //END TODO
  'user-search': '0f2fed896b93127038648b59da7ba643',
  'attachment-viewer': '01193fbf1b87ab079110c3830f702ce7',
  'reminder-date': 'fc9da39fe6eb93e664dc6ebbd8128a73',
  'ticket-side-conversations': 'bc4fe192ce3c6abe48f8ab249388beb1',
  'ticket-editor-side-conversations': 'bc4fe192ce3c6abe48f8ab249388beb1',
  'email-checker': '8c279595710510c24e0a58370befd7ea',
}
