import { useZendesk } from '@wrappers/Zendesk'
import { useMemo } from 'react'
import { useDevToolsShortcut } from 'src/lib/hooks/use-dev-tools-shortcut'

export const DeveloperToolsWrapper: React.FC<{ children?: React.ReactNode }> = props => {
  const { user } = useZendesk()
  const indigoverners = ['indigovern.com', 'indigov.com', 'indigov.us']
  const isIndigovUser = indigoverners.includes(user.email.split('@')[1])

  if (isIndigovUser) {
    return <AuthenticatedDeveloperTools>{props.children}</AuthenticatedDeveloperTools>
  }

  return <>{props.children}</>
}

const AuthenticatedDeveloperTools: React.FC<{ children?: React.ReactNode }> = props => {
  useDevToolsShortcut()

  const isOnPreviewUrl = useMemo(() => {
    if (typeof window === 'undefined') {
      return false
    }

    return window.location.origin.includes('vercel.app')
  }, [])

  return (
    <>
      {props.children}
      {isOnPreviewUrl && (
        <div className='pointer-events-none fixed bottom-2 right-2 bg-alert-red p-1 text-[8px] text-white'>
          Preview URL
        </div>
      )}
    </>
  )
}
