import { ZendeskClient } from '@monotypes/global/zendesk-client'
import { CustomFieldOptions } from '@monotypes/index'
import type { Zendesk as ZendeskAPI } from '@monotypes/zendesk'
import { logger } from '@utils/logger'

export const SecureSettings = {
  ProxyToken: '{{setting.secure_proxy_token}}',
  NorthstarToken: '{{setting.northstarToken}}',
  FlagsToken: '{{setting.flags_token}}',
}

interface EmailOptions {
  to: string
  from: string
  author: string
  first_name: string
  response_id: string
  response_title: string
  subject?: string
  logTags: string[]
}

export type TicketCount = {
  medianDate: string
  numTickets: { gteSolved: number; ltSolved: number; open: number; total: number }
  oldestDate: string
}

export class ZendeskApiConsumer {
  readonly zendeskClient: ZendeskApiClient

  constructor({ zendeskClient }: { zendeskClient: ZendeskApiClient }) {
    this.zendeskClient = zendeskClient
  }
}

export const headersWithZendeskCacheFix = (headers: Record<string, unknown> = {}): Record<string, unknown> => {
  // Warning! Suspected bug with zendesk etags for this resource!
  // Not providing this is causing aggressive caching in the browser, causing RAQ batches to appear invalid.
  // It seems like maybe zendesk isn't correctly setting etags for this endpoint, so ignoring for now.
  headers['if-none-match'] = null
  return headers
}

export const createZendeskClient = async () => {
  if (typeof window !== 'undefined' && window.client) {
    const context = await window.client.context()
    const metadata = await window.client.metadata()

    const user: ZendeskClient.User.CurrentUser = (await window.client.get('currentUser')).currentUser

    return new ZendeskApiClient({ context, metadata, user })
  }

  return {} as ZendeskApiClient // This promise should never be resolved server side
}

export const zendeskClient = createZendeskClient()

export class ZendeskApiClient {
  private readonly client = window.client
  public readonly context: ZendeskClientContext
  public readonly metadata: Metadata<ZendeskClient.Settings>
  public readonly settings: ZendeskClient.Settings
  public readonly user: ZendeskClient.User.CurrentUser
  public readonly subdomain: string
  public readonly ticketId: number | undefined
  public readonly newTicketId: string | undefined
  public readonly isDev = Boolean(process.env.DEBUG)

  constructor({
    context,
    metadata,
    user,
  }: {
    context: ZendeskClientContext
    metadata: Metadata<ZendeskClient.Settings>
    user: ZendeskClient.User.CurrentUser
  }) {
    this.context = context
    this.metadata = metadata
    this.settings = metadata.settings
    this.subdomain = context.account.subdomain
    this.user = user
    this.ticketId = context.ticketId
    this.newTicketId = context.newTicketId
  }

  public zendeskClient = this

  public getContext = () => this.context

  public getInstances = async (): Promise<{ instances: Record<string, { location: string; userId: number }> }> =>
    this.get('instances')

  public getCurrentAppDetails = (): { name: string; version: string; appId: number } => {
    const { name, version, appId } = this.metadata
    return {
      name,
      version,
      appId,
    }
  }

  public me = async (): Promise<ZendeskClient.User.User> =>
    (await this.request<{ user: ZendeskClient.User.User }>({ url: '/api/v2/users/me' })).user

  /**
   * Renews the current session and resets the logout timeout
   */
  public renewSession = async (): Promise<{ authenticity_token: string }> =>
    this.request({ url: '/api/v2/users/me/session/renew' })

  /*
   * Gets the user id for the current user profile being viewed
   */
  public getUserId = async () => await this.zafGet({ type: 'user.id' })

  /*
   * Gets the requester for the current ticket being viewed
   */
  public getTicketRequester = async () => await this.zafGet({ type: 'ticket.requester' })

  /**
   * Gets a user by id {@param userId}
   */
  public getUser = async (userId: number): Promise<ZendeskClient.User.User> =>
    (
      await this.request<{ user: ZendeskClient.User.User }>({
        type: 'GET',
        url: `/api/v2/users/${userId}`,
      })
    ).user

  /**
   * Creates a user with @param user and returns the new user
   */
  public createUser: ZendeskClient.User.Create = async user =>
    this.adminProxyRequest({
      data: {
        user,
      },
      url: `/users`,
      type: 'POST',
    })

  /**
   * Updates a user with @param user and returns the updated user
   */
  public updateUser: ZendeskClient.User.Update = async (userId, user) =>
    this.adminProxyRequest({
      data: {
        user,
      },
      url: `/users/${userId}`,
      type: 'PUT',
    })

  /**
   * Updates an array of users
   * @param users the list of users to update
   * @param user_fields list of user field updates
   * @returns a job status for the bulk update
   */
  public updateUsers = async (
    users: ZendeskClient.User.User[],
    userFields: { household_flag?: boolean; household_exclude_flag?: boolean },
  ): Promise<ZendeskClient.Job.Status> => {
    return (
      await this.adminProxyRequest<ZendeskAPI.SingleResults.JobStatus>({
        data: {
          user: {
            user_fields: userFields,
          },
        },
        url: `/users/update_many.json?ids=${users.map(u => u.id).join(',')}`,
        type: 'PUT',
      })
    ).job_status as ZendeskClient.Job.Status
  }

  /**
   * Queries for and returns a list of users
   */
  public searchUsers = async ({
    params,
    perPage = 30,
    page = 1,
  }: {
    params: ZendeskClient.UserSearchParams
    perPage?: number
    page?: number
  }): Promise<ZendeskClient.User.User[]> => {
    let url = `/api/v2/search/incremental?per_page=${perPage}&page=${page}&type=user&query=`
    Object.entries(params).forEach(([key, value]) => {
      if (value) url += `${key}:"${value}" `
    })

    return (
      await this.request<{ results: ZendeskClient.User.User[] }>({
        type: 'GET',
        url,
      })
    ).results
  }

  /**
   * Gets a list of users by an array of ids {@param userIds}
   */
  public getUsers = async (userIds: (string | number)[]): Promise<ZendeskClient.User.User[]> =>
    (
      await this.request<{ users: ZendeskClient.User.User[] }>({
        url: `/api/v2/users/show_many.json?ids=${userIds.join(',')}`,
      })
    ).users

  /**
   * Gets a list of users by group
   */
  public getUsersByGroup = async (groupId: number) =>
    (
      await this.request<{ users: ZendeskClient.User.User[] }>({
        url: `/api/v2/groups/${groupId}/users.json`,
      })
    ).users

  /**
   * Gets all groups
   */
  public getGroups = async () =>
    (
      await this.request<{ groups: ZendeskClient.Group.Group[] }>({
        url: '/api/v2/groups.json',
      })
    ).groups

  public getCustomRole = async (roleId: number) =>
    (
      await this.request<{ custom_role: ZendeskClient.Role.Role }>({
        url: `/api/v2/custom_roles/${roleId}.json`,
      })
    ).custom_role

  public getUserFields = async () =>
    (
      await this.request<{ user_fields: ZendeskClient.UserField[] }>({
        url: `/api/v2/user_fields.json`,
      })
    ).user_fields

  /**
   * Returns a strongly-typed (in-memory) settings object for the calling app. Requires passing in the app's setting interface.
   */
  public getSettings = <T extends ZendeskClient.Settings>() => this.settings as T

  /**
   * Returns the ticket currently in view
   */
  public getCurrentTicket = async () => await this.zafGet({ type: 'ticket' })

  /**
   *
   * Returns the editor channel in view
   */
  public getCurrentEditorChannel = async () => await this.zafGet({ type: 'ticket.editor.targetChannel' })

  /**
   * Returns a Ticket by {@param ticketId}
   */
  public getTicket = async (ticketId?: number | string): Promise<Required<ZendeskAPI.Ticket>> =>
    (
      await this.request<{ ticket: Required<ZendeskAPI.Ticket> }>({
        type: 'GET',
        url: `/api/v2/tickets/${ticketId ?? this.ticketId ?? this.newTicketId}`,
      })
    ).ticket

  public redactAttachment = async ({
    ticketId,
    commentId,
    attachmentId,
  }: {
    ticketId: number
    commentId: number
    attachmentId: string
  }) =>
    await this.adminProxyRequest({
      type: 'PUT',
      url: `/tickets/${ticketId}/comments/${commentId}/attachments/${attachmentId}/redact`,
    })

  public on: ZendeskClient.ZAF.On.Request = ({ type, callback }) => {
    this.client.on(type, callback)
  }

  public off: ZendeskClient.ZAF.Off.Request = ({ type, callback }) => {
    this.client.off(type, callback)
  }


  public async getAssignedTickets(user_id: number, pageSize=100) {
    return await this.getCursorPaginated<ZendeskAPI.Ticket>('tickets',
      `/api/v2/users/${user_id}/tickets/assigned?page[size]=${pageSize}`, [])
  }

  /**
   * A simple strongly-typed wrapper around window.client.invoke
   */
  public invoke: ZendeskClient.ZAF.Invoke.Request = async (invocation, args, kind?, duration?) => {
    if (kind) {
      if (duration) {
        return this.client.invoke(invocation, args, kind, duration)
      } else {
        return this.client.invoke(invocation, args, kind)
      }
    }
    return this.client.invoke(invocation, args)
  }

  public instance = async (id: string) => this.client.instance(id)

  public trigger = (name: string, args: unknown) => this.client.trigger(name, args)

  /**
   * Updates a Ticket.
   * This function should grow to encompass all types of ticket updates while exposing a strongly-typed interface.
   */
  public updateTicket: ZendeskClient.Ticket.Update.Request = request => {
    const data = {
      ticket: {
        ...('tags' in request && { tags: request.tags }),
        ...('customFields' in request && { custom_fields: request.customFields }),
        ...('comment' in request && { comment: request.comment }),
      },
    }

    return this.request({
      type: 'PUT',
      url: `/api/v2/tickets/${request.ticketId}.json`,
      data,
    })
  }

  /**
   * Fetches a Ticket's comments by {@param ticketId}
   */
  public getTicketComments = async (ticketId: number | string): Promise<Required<ZendeskAPI.Comment[]>> =>
    (
      await this.request<{ comments: Required<ZendeskAPI.Comment>[] }>({
        type: 'GET',
        url: `/api/v2/tickets/${ticketId}/comments.json`,
      })
    ).comments

  /**
   * Fetches batch options by the batch custom field ID in {@param batchIdKey}
   */
  public getBatchesById = async (batchIdKey: string): Promise<ZendeskClient.CustomField[]> => {
    const batchId = (await this.settings)[batchIdKey]
    const fields = await this.adminProxyRequest<ZendeskAPI.SingleResults.TicketField>({
      type: 'GET',
      url: `/ticket_fields/${batchId}.json`,
    })
    return fields.ticket_field.custom_field_options as ZendeskClient.CustomField[]
  }

  /**
   * Creates a batch option (adding a {@param option} to the batch dropdown)
   */
  public createBatchOption = async (option: {
    name: string
    value: string
  }): Promise<{
    custom_field_option: Required<CustomFieldOptions>
  }> => {
    const ticketFields = await this.getTicketFields()
    const batchField = ticketFields.find(field => field.title === 'Batch')!

    return this.adminProxyRequest({
      data: { custom_field_option: option },
      url: `/ticket_fields/${batchField.id}/options.json`,
      type: 'POST',
    })
  }

  /**
   * Fetches a user's current user groups by user ID in {@param id}
   */
  public getUserGroups = async (id: string | number): Promise<Array<ZendeskClient.User.Group>> =>
    (
      await this.request<{ groups: Array<ZendeskClient.User.Group> }>({
        url: `/api/v2/users/${id}/groups.json`,
      })
    ).groups

  /**
   * Fetches the logged-in user's restrictions.
   */
  public getCurrentUserRestrictions = async (): Promise<ZendeskClient.User.Restriction> => {
    const groups = await this.getUserGroups(this.user.id)
    const groupName = this.settings['zendesk_instance_type'] === 'default' ? 'Correspondence' : 'Staff'
    const group = groups.find(_ => _.name === groupName)
    const groupIds = group ? [group.id] : groups.filter(g => g.name !== 'Support').map(g => g.id)
    return {
      ids: groupIds,
      type: 'Group',
    }
  }

  public getMacrosFiltered = (params: { key: string; value: unknown }[], options?: { useAdminProxy: boolean }) => {
    let path = `/macros?page[size]=100`
    params && params.forEach(({ key, value }) => (path += `&${key}=${value}`))
    return options?.useAdminProxy
      ? this.getCursorPaginatedProxy<ZendeskAPI.Macro>('macros', path, [])
      : this.getCursorPaginated<ZendeskAPI.Macro>('macros', '/api/v2' + path, [])
  }

  public getTriggers = async (query = ''): Promise<Record<number, ZendeskAPI.Trigger>> => {
    try {
      const triggers = await this.getConcurrentPaginated<ZendeskAPI.Trigger>(
        `/api/v2/triggers?per_page=1000${query}`,
        'triggers',
      )

      return triggers.reduce<Record<number, ZendeskAPI.Trigger>>((acc, cur) => {
        acc[cur.id!] = cur
        return acc
      }, {} as Record<number, ZendeskAPI.Trigger>)
    } catch (error) {
      logger.error('Could not get triggers', { error })
      return {}
    }
  }

  public searchTriggers = async (query = ''): Promise<ZendeskAPI.Trigger[]> => {
    try {
      // only uses offset pagination
      return this.getPaginated<ZendeskAPI.Trigger>(
        'triggers',
        `/api/v2/triggers/search?per_page=1000&query=${query}`,
        [],
      )
    } catch (error) {
      logger.error('Could not get triggers', { error })
      return []
    }
  }

  public updateTrigger = async (triggerId: number, definition: Record<string, unknown>) => {
    try {
      await this.adminProxyRequest({
        url: `/triggers/${triggerId}`,
        data: JSON.stringify({ trigger: { ...definition } }),
        type: 'PUT',
      })
    } catch (error) {
      logger.error('Error updating trigger | updateTrigger()', {
        metadata: { triggerId },
        error,
      })
    }
  }

  public deleteTriggers = async (triggers: ZendeskAPI.Trigger[]) => {
    for (const trigger of triggers) {
      try {
        await this.adminProxyRequest({
          type: 'DELETE',
          url: `/triggers/${trigger.id}`,
        })
      } catch (error) {
        logger.error('Could not delete triggers', { metadata: { triggers }, error })
      }
    }
  }

  /**
   * Creates a Trigger. {@param category} decides what category is assigned to the Trigger - these are mandatory.
   * Not supplying a category will lead to triggers being fired in the wrong order - catastrophic.
   * https://indigov.atlassian.net/browse/ENG-3590
   */
  public createTrigger: ZendeskClient.Trigger.Create = async ({ trigger, category }) => {
    const categories = await this.getTriggerCategories()
    const targetCategory = categories.find(_ => _.name === category)

    if (!targetCategory) {
      logger.error(`Target category not found: ${category}`)
      throw new Error()
    }

    return this.adminProxyRequest({
      type: 'POST',
      data: {
        trigger: {
          ...trigger,
          category_id: targetCategory.id,
        },
      },
      url: `/triggers.json`,
    })
  }

  public deactivateTriggerById = async (triggerId: number) => {
    try {
      await this.adminProxyRequest({
        type: 'PUT',
        data: {
          trigger: {
            active: false,
          },
        },
        url: `/triggers/${triggerId}`,
      })
      logger.info(`Successfully deactivated trigger', ${triggerId}.`)
      return 'Success'
    } catch (error) {
      logger.error('Unable to deactivate trigger', { metadata: { triggerId }, error })
      return 'Fail'
    }
  }

  public getWebhooks = async () => {
    try {
      return (await this.request<ZendeskAPI.ListResults.Webhooks>({ url: '/api/v2/webhooks' })).webhooks
    } catch (error) {
      throw new Error(`Unable to get webhooks. ${error}.`)
    }
  }

  public notify = async (data: any) => {
    return this.request({
      contentType: 'application/json',
      url: '/api/v2/apps/notify',
      data,
      type: 'POST',
    })
  }

  public getTicketCountsByTag = async (tag: string, options: Zendesk.SearchOptions) => {
    let searchString = `tags:${tag}`

    if (options.status) searchString += ` ${options.status.map((s: string) => `status:${s} `).join(' ')}`
    if (options.created) searchString += ` created${options.created}`
    if (options.updated) searchString += ` updated${options.updated}`
    try {
      const res = await this.request<ZendeskAPI.PaginatedResults.Tickets>({
        url: `/api/v2/search?query=${searchString}`,
      })
      return res.count
    } catch (error) {
      throw new Error(`Unable to get tickets. ${error}.`)
    }
  }

  /**
   * Creates a Macro.
   */
  public createMacro: ZendeskClient.Macro.Create = macro =>
    this.adminProxyRequest({
      type: 'POST',
      data: { macro },
      url: `/macros.json`,
    })

  public getMacro: ZendeskClient.Macro.GetOne = async (macroId: string) => {
    const { macro } = await this.adminProxyRequest<{ macro: ZendeskAPI.Macro }>({
      url: `/macros/${macroId}.json`,
    })
    return macro
  }

  public getMacros: ZendeskClient.Macro.Get = async (params?: string) => {
    // per_page of 1k is supported, but in practice, it is slower than multiple smaller calls
    // unless you're using ZDAAP, in which case, 1 call of 1k is quicker than 10 calls of 100
    const query = params?.includes('per_page') ? `${params || ''}` : `per_page=100&${params || ''}`
    return this.getConcurrentPaginated<ZendeskAPI.Macro>(`/api/v2/macros?${query}`, 'macros', {
      zdaap: true, // without ZDAAP, it will only return the current user's macros
    })
  }

  public updateMacro: ZendeskClient.Macro.Update = async props => {
    return this.adminProxyRequest({
      type: 'PUT',
      data: { macro: props.macro },
      url: `/macros/${props.id}.json`,
    })
  }

  public updateManyMacro: ZendeskClient.Macro.UpdateMany = async macros => {
    return this.adminProxyRequest({
      type: 'PUT',
      data: { macros },
      url: `/macros/update_many.json`,
    })
  }

  public deleteMacro: ZendeskClient.Macro.Delete = async id => {
    return this.adminProxyRequest({
      type: 'DELETE',
      url: `/macros/${id}.json`,
    })
  }

  /**
   * Deletes a Zendesk resource in {@param type} deletable by a simple ID in {@param id}. This may be broken down into simpler functions
   */
  public deleteResource = (type: ZendeskClient.Resource, id: string | number) =>
    this.adminProxyRequest({
      type: 'DELETE',
      url: `/${type.valueOf()}/${id}.json`,
    })

  /**
   * Fetches all Trigger Categories available. This requires manually enabling Trigger Categories on an account.
   */
  public getTriggerCategories = async (
    params?: Array<{ key: 'include'; value: 'rule_counts' } | { key: 'sort'; value: ZendeskClient.SortParams }>,
  ): Promise<ZendeskClient.TriggerCategory.Category[]> => {
    let url = '/api/v2/trigger_categories?'
    params && params.forEach(({ key, value }) => (url += `&${key}=${value}`))
    return this.getCursorPaginated<ZendeskClient.TriggerCategory.Category>('trigger_categories', url, [])
  }

  public searchForResource: ZendeskClient.Resource.Search.Request = (type, request) =>
    this.adminProxyRequest({
      url: `/${type}/search.json?active=true&query=${request.query}`,
    })

  /**
   * Fetches all Ticket Fields in an account.
   */
  public getTicketFields = async (): Promise<ZendeskClient.TicketField.Type[]> =>
    (
      await this.adminProxyRequest<ZendeskAPI.PaginatedResults.TicketFields>({
        url: '/ticket_fields',
        type: 'GET',
        headers: headersWithZendeskCacheFix(),
      })
    ).ticket_fields as ZendeskClient.TicketField.Type[]

  public getRequesterTickets = async(userId: number, pageSize = 100) => {
    return await this.getCursorPaginated<ZendeskAPI.Ticket>('tickets', `/api/v2/users/${userId}/tickets/requested?page[size]=${pageSize}`, [])
  }
  /**
   * Updates Ticket Field
   */
  public updateTicketField: ZendeskClient.TicketField.Update.Request = async ({ ticketFieldId, ticketField }) =>
    (
      await this.adminProxyRequest<ZendeskAPI.SingleResults.TicketField>({
        url: `/ticket_fields/${ticketFieldId}`,
        type: 'PUT',
        data: {
          ticket_field: ticketField,
        },
      })
    ).ticket_field

  /**
   * Strongly typed wrapper around the client.get ZAF function.
   * This function should grow over time.
   */
  public zafGet: ZendeskClient.ZAF.Get.Request = async request => {
    switch (request.type) {
      case 'customField':
        // needs to be typed by the key is dynamic {errors: unknown; `ticket.customField:custom_field_${request.id}`: number }
        // eslint-disable-next-line no-case-declarations
        const res = await this.get<Record<string, unknown>>(`ticket.customField:custom_field_${request.id}`)
        return res ? res[`ticket.customField:custom_field_${request.id}`] : null
      case 'ticket.subject':
        return (await this.get<{ 'ticket.subject': any }>('ticket.subject'))['ticket.subject']
      case 'user.id':
        return (await this.get<{ errors: unknown; 'user.id': number }>('user.id'))['user.id']
      case 'ticket':
        return await this.get('ticket')
      case 'currentUser.groups':
        return (await this.get<{ 'currentUser.groups': any }>('currentUser.groups'))['currentUser.groups']
      case 'currentUser.id':
        return (await this.get<{ 'currentUser.id': any }>('currentUser.id'))['currentUser.id']
      case 'ticket.tags':
        return (await this.get<{ 'ticket.tags': any }>('ticket.tags'))['ticket.tags']
      case 'ticket.comment':
        return (await this.get<{ 'ticket.comment': any }>('ticket.comment'))['ticket.comment']
      case 'ticket.comments':
        return await this.get('ticket.comments')
      case 'ticket.requester':
        return (await this.get<{ 'ticket.requester': any }>('ticket.requester'))['ticket.requester']
      case 'ticket.editor.targetChannel':
        return (await this.get<{ 'ticket.editor.targetChannel': unknown }>('ticket.editor.targetChannel'))[
          'ticket.editor.targetChannel'
        ]
    }
  }

  public zafSet: ZendeskClient.ZAF.Set.Request = async ({ type, value }) => {
    switch (type) {
      case 'comment.text':
        return (await this.set<{ 'comment.text': string; errors: Record<string, unknown> }>({ type, value }))[
          'comment.text'
        ]
    }
  }

  /**
   * Fetches a Ticket Custom Field by {@param id}
   */
  public getTicketCustomField = (id: string | number) =>
    this.get<Record<string, unknown>>(`ticket.customField:custom_field_${id}`)

  /**
   * Gets a list of Ticket Forms
   */
  public getTicketForms = async (): Promise<ZendeskClient.TicketForm.Type[]> =>
    (await this.request<{ ticket_forms: ZendeskAPI.TicketForm[] }>({ url: '/api/v2/ticket_forms' }))
      .ticket_forms as ZendeskClient.TicketForm.Type[]

  /**
   *
   * @param userId: number
   * @returns identities: Identity[]
   */
  public getUserIdentities = async (id?: number) => {
    try {
      return (await this.request<ZendeskAPI.PaginatedResults.Identities>({ url: `/api/v2/users/${id}/identities` }))
        .identities
    } catch (error) {
      throw new Error(`Unable to fetch user identities ${error}`)
    }
  }

  /**
   * Sends an email preview
   */
  public sendEmailPreview = async () => {
    const { ticket } = (await this.zafGet({ type: 'ticket' })) as any // TODO
    const currentUserId = await this.zafGet({ type: 'currentUser.id' })
    const data = {
      ticket: {
        submitter_id: currentUserId,
        assignee_id: ticket.assignee.user.id,
        requester_id: ticket.requester.id,
        subject: ticket.subject,
        comment: {
          html_body: ticket.comment.text,
        },
        tags: ticket.tags.concat(['email-preview']),
        ticket_form_id: ticket.form?.id,
      },
    }
    return await this.request({
      contentType: 'application/json',
      url: `/api/v2/tickets`,
      data,
      type: 'POST',
    })
  }

  public adminProxyRequest = async <T>({ type = 'GET', data, url }: ZendeskClient.RequestOptions): Promise<T> => {
    // If insecure token is still present, use that over secure token (to account for legacy app setups)
    const token = (await this.settings)['proxy_token'] as string
    return this.request<T>({
      type,
      data,
      url: 'https://zdaap.indigov.cloud/proxy',
      headers: {
        'X-ZDAAP-Path': url,
        'X-ZDAAP-Subdomain': await this.subdomain,
        'X-ZDAAP-Token': token || SecureSettings.ProxyToken,
      },
      secure: !token,
    })
  }

  public uploadImage = async (file: File): Promise<{ data: { link: string } }> => {
    try {
      const tokenRes = await this.request<{ auth: string }>({
        contentType: 'application/json',
        type: 'POST',
        url: '/api/v2/users/radar_token.json',
      })

      const link: string = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `https://${this.subdomain}.zendesk.com/api/v2/uploads.json?filename=${file.name}&inline=true`)
        xhr.setRequestHeader('Authorization', `Basic ${tokenRes.auth}`)
        xhr.send(file)
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText)
          resolve(response.upload.attachment.content_url)
        })
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText)
          reject(error)
        })
      })

      return { data: { link } }
    } catch (error) {
      logger.error(`couldn't upload image`, {
        error,
      })

      return { data: { link: '' } }
    }
  }

  /**
   * A reusable, generic method with logging that should be used for all outbound HTTP requests to Zendesk.
   * @param type HTTP method type
   * @param data Loosely typed as an object for now, consider revisiting
   * @param url Either a fully qualified URL or a Zendesk API path
   * @param secure Denotes whether this is a secure request (to be routed to the Zendesk proxy to fill in secrets)
   * @param headers Strongly typed, please update type if this needs expansion
   */
  public request = async <T = any>({
    type = 'GET',
    data,
    url,
    secure,
    headers = {},
    contentType = 'application/json',
    source = 'ZD Client',
    stringify = true,
    logError = true,
    dataType = 'JSON',
  }: ZendeskClient.RequestOptions): Promise<T> => {
    const mergedHeaders = {
      ...headers,
      'x-trace-id': logger.traceId,
      // Settings needed for requests to Bulk API service
      'x-email': this.settings['backend_api_email'] as string,
      'x-token': this.settings['backend_api_token'] as string,
      'x-ig-bulk-api-token': this.settings['BulkUpdatePassword'] as string,
      'x-subdomain': this.subdomain,
    }

    const log = `| ${source} | [${type}] ${secure ? '*PROXY* ' : ''}${
      source === 'GraphQL' ? url : secure ? headers?.['X-ZDAAP-Path'] : url
    } `

    let logData: Record<string, unknown> = {
      data,
      headers: {
        ...mergedHeaders,
        // Clearing out secrets
        'X-ZDAAP-Token': Boolean(mergedHeaders['X-ZDAAP-Token']),
        'X-Token': Boolean(mergedHeaders['X-Token']),
        'x-ig-bulk-api-token': Boolean(mergedHeaders['x-ig-bulk-api-token']),
        'x-office': mergedHeaders['x-office'],
      },
    }

    return this.client
      .request({
        contentType,
        ...(data && { data: stringify ? JSON.stringify(data) : (data as string) }),
        ...(dataType && { dataType }),
        headers: mergedHeaders,
        type,
        url,
        secure,
      })
      .then(response => {
        if (this.isDev) {
          logData = { ...logData, response }
        }
        logger.info(log, { metadata: logData })
        return response
      })
      .catch(error => {
        logError && logger.error(log, { metadata: logData, error })
        throw error
      })
  }

  /**
   * A reusable. generic method with logging that should be used for all .get requests to the ZAF
   */
  public get = async <T>(key: string | string[]): Promise<T> => {
    return this.client.get(key).catch(error => {
      logger.error(`|>ZD Client [ZAF:GET] ${key}`, { error })
      throw error
    })
  }

  /**
   * Generic wrapper for ZAF .set requests
   */
  public set = async <T>({ type, value }: { type: string; value: string | null }): Promise<T> => {
    logger.info(`| ZD Client | [ZAF:SET] ${type}:${value}`)
    return this.client.set(type, value).catch(error => {
      logger.error(`|>ZD Client [ZAF:SET] ${type}:${value}`, { error })
      throw error
    })
  }

  // TODO: This function needs a typing overhaul
  private getCursorPaginated = async <T>(
    field: string,
    url: string,
    results: T[],
    maxResult?: number,
  ): Promise<Array<T>> => {
    return this.getCursorized(field, url, results, this.request, maxResult)
  }

  private getCursorPaginatedProxy = async <T>(
    field: string,
    url: string,
    results: T[],
    maxResult?: number,
  ): Promise<Array<T>> => {
    return this.getCursorized(field, url, results, this.adminProxyRequest, maxResult)
  }

  private normalizeUrl = (
    url: string,
    field: string,
    reqFunction: typeof this.request | typeof this.adminProxyRequest,
  ): string => (reqFunction === this.adminProxyRequest ? url.slice((url || '').indexOf(field) - 1) : url)

  private getCursorized = async <T>(
    field: string,
    url: string,
    results: T[],
    reqFunction: typeof this.request | typeof this.adminProxyRequest,
    maxResult?: number,
  ): Promise<Array<T>> => {
    if (!url) {
      return results
    }
    const response = await reqFunction<ZendeskClient.Pagination<T> & { after_url?: string }>({ url })
    const resp = response as unknown as any
    results = results.concat(resp[field])

    if (maxResult && results.length >= maxResult) return results
    if (response.after_url) {
      return await this.getCursorized(
        field,
        this.normalizeUrl(response.after_url, field, reqFunction),
        results,
        reqFunction,
        maxResult,
      )
    } else if (response?.meta?.has_more && response.links.next) {
      return await this.getCursorized(
        field,
        this.normalizeUrl(response.links.next, field, reqFunction),
        results,
        reqFunction,
        maxResult,
      )
    }
    return results
  }

  private getConcurrentPaginated = async <T>(
    /** The full API path with query params (include /api/v2). Include ?per_page or will default to 1000; e.g., /api/v2/macros?per_page=100 */
    path: string,
    /** The JSON field containing the array of results. */
    field: string,
    opts?: {
      /** Proxy the calls through ZDAAP? */
      zdaap?: true
    },
  ): Promise<Array<T>> => {
    // helper function to generate paths with search args ?page=&per_page=
    const createFinalPath = (page: number, perPage: number) => {
      const url = new URL(path, 'https://notused.com')
      url.searchParams.set('page', `${page}`)
      url.searchParams.set('per_page', `${perPage}`)
      // ZDAAP won't work with the /api/v2 prefix
      const finalPath = opts?.zdaap ? url.pathname.replace(/^\/api\/v2/, '') : url.pathname
      return finalPath + url.search
    }

    // helper function to either use ZDAAP or direct requests
    const makeRequest = <T>(path: string) => (opts?.zdaap ? this.adminProxyRequest : this.request)<T>({ url: path })

    // make the leanest request possible just to get the total results count
    const firstPath = createFinalPath(1, 1)
    const firstRes = await makeRequest<Record<string, any> & ZendeskClient.OldPagination<T>>(firstPath)
    const totalResultsCount = firstRes.count

    // now fetch the entirety of the data set concurrently
    const perPage = Number(new URL(path, 'https://notused.com').searchParams.get('per_page') || '1000') // use the original per_page arg with a default
    const numPages = Math.ceil(totalResultsCount / perPage)
    let results: T[] = []
    // we needn't worry about awaiting too many Promises, the browser should handle concurrency (chrome defaults to 6)
    await Promise.all(
      [...Array(numPages)].map(async (_, i) => {
        const pagePath = createFinalPath(i + 1, perPage)
        const res = await makeRequest<Record<string, any> & ZendeskClient.OldPagination<T>>(pagePath)
        results = results.concat(res[field])
      }),
    )

    return results
  }

  // TODO: This function seems to fire too fast, Zendesk starts challenging
  // TODO: Needs a typing overhaul
  private getPaginated = async <T>(field: string, url: string, results: T[], maxResult?: number): Promise<Array<T>> => {
    const response = await this.request<Record<string, any> & ZendeskClient.OldPagination<T>>({ url })
    results = results.concat(response[field])
    if (maxResult && results.length >= maxResult) return results
    return response.next_page ? await this.getPaginated(field, response.next_page, results, maxResult) : results
  }

  private getPaginatedProxy = async <T>(field: string, url: string, results: T[]): Promise<Array<T>> => {
    const response = await this.adminProxyRequest<ZendeskClient.OldPagination<T>>({ url })
    results = results.concat(response[field])
    const startIndex = (response.next_page || '').indexOf(field) - 1
    const nextPage = response.next_page?.slice(startIndex)

    return nextPage ? await this.getPaginatedProxy(field, nextPage, results) : results
  }

  public async getTicketCounts(tags: string[]): Promise<Array<{ [key: string]: TicketCount }>> {
    const Tags = [...tags, 'getTicketCounts']
    try {
      const { metrics } = await this.request<any>({
        contentType: 'application/json',
        url: String(this.metadata?.settings.ticket_count_endpoint) || '',
        type: 'POST',
        dataType: 'JSON',
        data: {
          subdomain: this.context?.account.subdomain || '',
          tags: Array.from(new Set(tags)).join(','),
        },
      })

      return metrics
    } catch (error) {
      logger.error('Error fetching ticket counts | getTicketCounts()', {
        tags: Tags,
        error,
      })

      return []
    }
  }

  public createTicket = (body: Partial<ZendeskClient.Ticket.Type> & { subject: string; description: string }) =>
    this.request<ZendeskClient.Ticket.Type>({
      url: '/api/v2/tickets',
      type: 'POST',
      contentType: 'application/json',
      data: { ticket: body },
    })

  // TODO: Move this to backend or use sparkpost instead
  public async sendmail({ author, first_name, response_id, response_title, from, to, logTags }: EmailOptions) {
    try {
      const link = `https://${this.subdomain}.zendesk.com/agent/apps/response-queue/edit/response/${response_id}`
      const msg = {
        from: {
          email: from,
        },
        personalizations: [
          {
            dynamic_template_data: {
              author,
              first_name,
              link,
              response_name: response_title,
            },
            to: [{ email: to }],
          },
        ],
        reply_to: {
          email: from,
        },
        template_id: 'd-4485b7886aa847e6b5c074fda2b082ce',
      }
      this.request<any>({
        contentType: 'application/json',
        data: msg,
        dataType: 'JSON',
        headers: {
          Authorization: `Bearer ${this.metadata.settings.sendgrid_api_key}`,
        },
        type: 'POST',
        url: 'https://api.sendgrid.com/v3/mail/send',
      }).then(() =>
        logger.info(`Email sent from ${from} to ${to}`, {
          metadata: { to, from, author, first_name, response_id, response_title, link },
          tags: logTags,
        }),
      )
    } catch (error) {
      logger.error('Error sending mail', {
        metadata: {
          author,
          first_name,
          response_id,
          response_title,
          from,
          to,
        },
        tags: logTags,
        error,
      })
    }
  }
}
