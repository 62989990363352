import Bugsnag from '@bugsnag/js'
import { ZendeskApiClient } from '@client/zendesk-api'
import { AppName } from '@constants/appName'
import { datadogRumKeys } from '@constants/datadog-rum-keys'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum-slim'
import { toError } from '@monotypes/errors'
import { ZendeskClient } from '@monotypes/global/zendesk-client'
import { nanoid } from '@reduxjs/toolkit'

type IndigovLogData = { error?: unknown; tags?: string[]; metadata?: Record<string, any> }
type IndigovLogFunction = (message: string, data?: IndigovLogData) => void

interface ILogger {
  info: IndigovLogFunction
  error: IndigovLogFunction
  // This is optional just to keep types happy
  // The logger is initialized as a vanilla Datadog logger (just in case) without this key. but quickly overwritten with the Logger class that has this key.
  traceId?: string
}

// Shareable logging util class
class Logger {
  private readonly user: ZendeskClient.User.CurrentUser
  private readonly subdomain: string
  private readonly context: WindowClientContext
  private zafMetadata: Metadata<any>
  private logger: ILogger
  public readonly traceId: string

  constructor(user: ZendeskClient.User.CurrentUser, context: WindowClientContext, zafMetadata: Metadata<any>) {
    this.traceId = nanoid(10)
    this.user = user
    this.context = context
    this.subdomain = context.account.subdomain
    this.zafMetadata = zafMetadata
    this.logger =
      process.env.NODE_ENV === 'production' && !window.location.search.includes('zat=true')
        ? datadogLogs.logger
        : {
            // eslint-disable-next-line no-console
            info: console.log,
            // eslint-disable-next-line no-console
            error: console.error,
          }
  }

  info: IndigovLogFunction = (message, data = {}) => {
    this.logger.info(message, this.logMeta(data))
  }

  error: IndigovLogFunction = (message, data = {}) => {
    this.logger.error(message, this.logMeta(data))

    if (data.error) {
      const notifiableError = toError(data.error, message)
      Bugsnag.notify(notifiableError, e => data.metadata && e.addMetadata('Logger', data.metadata))
    }
  }

  private logMeta = ({ tags, error, metadata }: IndigovLogData) => {
    return {
      traceId: this.traceId,
      subdomain: this.subdomain,
      ...(tags && { tags }),
      app: {
        name: this.zafMetadata.name,
        version: this.zafMetadata.version,
        id: this.zafMetadata.appId,
      },
      ...(this.context.ticketId && { ticketId: this.context.ticketId }),
      email: this.user?.email,
      ...(error ? { error } : {}),
      ...(metadata && { metadata }),
    }
  }
}

// The logger is initially set to a vanilla Datadog logger
export let logger: ILogger =
  // eslint-disable-next-line no-console
  process.env.NODE_ENV === 'production' ? datadogLogs.logger : { info: console.log, error: console.error }

// Guarding against accidental multiple initializations of the logger
let isLoggerInit = false

// appName should be hyphenated with no spaces
export const initLogger = async (appName: string, zendeskClient: ZendeskApiClient) => {
  if (!isLoggerInit) {
    try {
      const context = zendeskClient.context
      const metadata = zendeskClient.metadata
      const user = zendeskClient.user

      initDatadog({ appName, user, subdomain: context.account.subdomain, version: metadata.version })
      logger = new Logger(user, context, metadata)
      isLoggerInit = true
    } catch (e) {
      initDatadog({
        appName: appName || 'unknown-app',
        user: zendeskClient?.user || { name: 'unknown-user', email: 'unknown-email' },
        subdomain: zendeskClient?.context?.account?.subdomain || 'unknown-subdomain',
        version: zendeskClient?.metadata?.version || 'unknown-version',
      })
      logger.error(`Error fetching context for logger init: ${(e as Error)?.message || JSON.stringify(e)}`)
    }
  }
}

const datadogIsDisabledForSubdomain = (subdomain: string) => {
  if (process.env.ENABLE_DATADOG_RUM_FOR_ALL_DOMAINS) {
    return false
  }

  const subdomainsWithoutDatadogRumTracking = ['indigov', 'pdi-']

  return subdomainsWithoutDatadogRumTracking.some(disabledSubdomain => subdomain.startsWith(disabledSubdomain))
}

const datadogIsDisabledForUser = (email: string) => {
  return email.includes('@indigov')
}

const initDatadog = ({
  appName,
  user,
  subdomain,
  version,
}: {
  appName: string
  user: ZendeskClient.User.CurrentUser
  subdomain: string
  version: string
}) => {
  datadogLogs.init({
    clientToken: 'pub5384863e197aa07641cb90355f1223cf',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    env: process.env.NODE_ENV || 'development',
    service: appName,
  })

  const rumKeys = datadogRumKeys[appName as AppName]
  if (
    !rumKeys.applicationId ||
    !rumKeys.clientToken ||
    datadogIsDisabledForSubdomain(subdomain) ||
    datadogIsDisabledForUser(user.email)
  ) {
    return
  }

  // Note that we are using the "slim" version of Datadog RUM which doesn't
  // include session replay functionality. We can use the regular (larger) package
  // if we need the session replay functionality.
  datadogRum.init({
    applicationId: rumKeys.applicationId,
    clientToken: rumKeys.clientToken,
    site: 'datadoghq.com',
    service: appName,
    // TODO: this is probably not a useful version number, fix
    version: version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackViewsManually: true,
    useCrossSiteSessionCookie: true,
  })

  datadogRum.setUser({
    name: user.name,
    email: user.email,
  })

  datadogRum.addRumGlobalContext('meta', {
    subdomain,
  })
}
