import { ApolloProvider } from '@apollo/client'
import { SecureSettings } from '@client/zendesk-api'
import { createApolloClient } from '@graphql/apollo'
import React, { FC, useMemo } from 'react'
import { useZendesk } from './Zendesk'

export const ApolloWrapper: FC<{ authToken: string; isActive: boolean; children?: React.ReactNode }> = ({
  authToken,
  isActive = false,
  children,
}) => {
  const zendeskClient = useZendesk()
  const northstarToken = authToken || SecureSettings.NorthstarToken

  const apolloClient = useMemo(() => {
    return createApolloClient({ northstarToken, zendeskClient })
  }, [])
  if (!isActive) return <>{children}</>

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
