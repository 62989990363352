import {
  FlagContext,
  FlaggedAppName,
  flaggedApps,
  flags,
  getFlagOverrides,
  FlagsClient,
  FlagName,
} from '@client/flags-client'
import { AppName } from '@constants/appName'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useZendesk } from './Zendesk'

const FeatureFlagContext = createContext<FlagContext>({} as FlagContext)

// Flags returns all flags to us with colons instead of periods, so we should match
const sanitizeKey = (key: string) => key.replace(/\./g, ':')

export const FeatureFlagWrapper: FC<{ appName: AppName; children?: React.ReactNode }> = ({ children, appName }) => {
  const appIsFeatureFlagged = flaggedApps.includes(appName as FlaggedAppName) || flags.some(f => f.__appname === 'all')
  const appFlags = appIsFeatureFlagged ? flags.filter(f => f.__appname === appName || f.__appname === 'all') : []

  // store a mapping of each flag we want to fetch to its friendly name
  // e.g. frontend:flags:strikethrough: "useStrikethrough"
  const appFlagToNameMapping = appFlags.reduce((obj: { [key: string]: FlagName }, flag) => {
    obj[sanitizeKey(flag.flag)] = flag.name
    return obj
  }, {})

  const defaultFlags = flags.reduce((obj: FlagContext, flag) => {
    obj[flag.name] = false
    return obj
  }, {} as FlagContext)

  const featureFlagOverrides = useMemo(() => {
    return getFlagOverrides()
  }, [])

  const SESSION_STORAGE_KEY = `flagsCache`

  const saveFlagsOffline = (flagContext: FlagContext) => {
    try {
      window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(flagContext))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Unable to save cached flags to sessionStorage')
    }
  }

  const loadOfflineFlags = () => {
    try {
      const savedData = window.sessionStorage.getItem(SESSION_STORAGE_KEY)
      if (savedData) {
        return JSON.parse(savedData) as FlagContext
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Unable to parse cached flags from sessionStorage')
    }

    return {} as FlagContext
  }

  const [featureFlags, setFeatureFlags] = useState<FlagContext>(loadOfflineFlags)
  const { subdomain } = useZendesk()

  const zendeskClient = useZendesk()
  const flagsClient = new FlagsClient({ zendeskClient })

  useEffect(() => {
    const getFeatureFlags = async () => {
      updateFlags()
    }

    appIsFeatureFlagged && getFeatureFlags()
  }, [appIsFeatureFlagged])

  const updateFlags = async () => {
    const flagContext = {} as FlagContext
    if (appFlags) {
      const flagValues = await flagsClient.getFlags({ flags: Object.keys(appFlagToNameMapping), subdomain })
      for (const flag in flagValues) {
        const name = appFlagToNameMapping[flag]
        const value = flagValues[flag]
        if (name) {
          flagContext[name] = value
        }
      }
    }

    // build our flags object in the following order (last value wins):
    // list of all flags, with values set to false by default
    // values of flags currently stored in session storage, if any (this may include flag values we pulled for other apps)
    // values of flags we just pulled from the FLAGS service for this app
    // any local developer overrides
    const flagContextWithOverrides = { ...defaultFlags, ...featureFlags, ...flagContext, ...featureFlagOverrides }
    saveFlagsOffline(flagContextWithOverrides)
    setFeatureFlags(flagContextWithOverrides)
  }

  if (!appIsFeatureFlagged) return <>{children}</>

  if (!featureFlags) {
    return null
  }

  return Object.keys(featureFlags).length ? (
    <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
  ) : null
}

export const useFeatureFlags = () => useContext(FeatureFlagContext)

// The API uses fully qualified feature flags, e.g. "bulkapi.flags.unlayerenabled".
// In the client we usually use the shortcut names like "useUnlayer"
export const getFeatureFlagsHeader = () => {
  const featureFlagOverrides = getFlagOverrides()

  if (!featureFlagOverrides || Object.keys(featureFlagOverrides).length === 0) {
    return
  }
  const fullyQualifiedFeatureFlags: Record<string, boolean> = {}

  Object.entries(featureFlagOverrides).forEach(([flag, flagState]) => {
    const fullNameForFlag = flags.find(f => f.name === flag)?.flag

    if (fullNameForFlag && flagState !== undefined) {
      fullyQualifiedFeatureFlags[fullNameForFlag] = flagState
    }
  })

  return JSON.stringify(fullyQualifiedFeatureFlags)
}
