import { ZendeskApiClient, zendeskClient as zdClient } from '@client/zendesk-api'
import { initLogger } from '@utils/logger'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'

const ZendeskContext = createContext({} as ZendeskApiClient)

export const ZendeskWrapper: FC<{ appName?: string; children?: React.ReactNode }> = ({ children, appName }) => {
  const [zendeskClient, setZendeskClient] = useState<ZendeskApiClient>()

  useEffect(() => {
    const initZendeskClient = async () => {
      const client = await zdClient
      setZendeskClient(client)
    }

    initZendeskClient()
  }, [])

  useEffect(() => {
    if (appName && zendeskClient) {
      initLogger(appName, zendeskClient)
    }
  }, [appName, zendeskClient])

  return zendeskClient ? <ZendeskContext.Provider value={zendeskClient}>{children}</ZendeskContext.Provider> : null
}

export const useZendesk = () => useContext(ZendeskContext)
