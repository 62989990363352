import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * This hook registers a key combination (Ctrl + Alt + CMD/WIN + D) that forwards the user
 * to our developer tools page.
 * If the user is already on the developer page, the same combination will go "back" in the browser
 */
export const useDevToolsShortcut = () => {
  const router = useRouter()

  useEffect(() => {
    const eventListener = (event: KeyboardEvent) => {
      if (event.code === 'KeyD' && event.ctrlKey && event.metaKey && event.altKey) {
        if (router.route === '/dev/flags') {
          router.back()
        } else {
          router.push('/hosted/dev/tools')
        }
      }
    }

    window.addEventListener('keydown', eventListener)

    return () => window.removeEventListener('keydown', eventListener)
  }, [router.route])
}
