import type Event from '@bugsnag/core/types/event'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import type { AppName } from '@constants/appName'
import { bugsnagApiKeys } from '@constants/bugsnag-api-keys'
import { toError } from '@monotypes/errors'
import React, { Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useZendesk } from './Zendesk'

declare module '@bugsnag/js' {
  interface BugsnagStatic {
    _client: {
      _config: {
        appVersion: string
      }
    }
  }
}

// Custom error handler to restructure non-standard Error objects into the shape Bugsnag can ingest
// @see https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#updating-events-using-callbacks
const onError = (event: Event) => {
  // Assume that handled errors (surfaced by .notify() are well-formed)
  if (event.unhandled) {
    const { name, message } = toError(event.originalError)

    const updatedErrors =
      event.errors &&
      event.errors.map(e => ({
        ...e,
        errorMessage: message,
        errorClass: name,
        stacktrace: e.stacktrace,
        type: e.type,
      }))

    event.errors = updatedErrors
  }
}

export const BugsnagWrapper = ({ children, appName }: PropsWithChildren<{ appName: AppName }>) => {
  const { user, subdomain, ticketId, getCurrentAppDetails } = useZendesk()
  const { version } = getCurrentAppDetails()
  const [initialized, setInitialized] = useState(false)

  let releaseStage = 'development'

  if (window.location.hostname.includes('localhost')) {
    releaseStage = 'test'
  } else if (subdomain.includes('indigovstaging') || subdomain.includes('indigovdev')) {
    releaseStage = 'staging'
  } else if (process.env.NODE_ENV) {
    releaseStage = process.env.NODE_ENV
  }

  useEffect(() => {
    if (!appName) return

    Bugsnag.start({
      apiKey: bugsnagApiKeys[appName],
      releaseStage,
      enabledReleaseStages: ['production'],
      plugins: [new BugsnagPluginReact()],
      onError,
    })

    Bugsnag.addMetadata('user', {
      id: user.id,
      name: user.name,
      email: user.email,
    })

    Bugsnag.addMetadata('app', {
      ticketId,
      subdomain,
      version,
    })

    Bugsnag._client._config.appVersion = version
    setInitialized(true)
  }, [appName])

  const ErrorBoundary = useMemo(
    () => (initialized ? Bugsnag.getPlugin('react')!.createErrorBoundary(React) : Fragment),
    [Bugsnag],
  )

  return <ErrorBoundary>{children}</ErrorBoundary>
}
