import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { HostedWrapper } from 'src/lib/wrappers/Hosted'

// Styles that have to be made available globally
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'src/lib/styles/global.css'
import '../src/apps/bulk-mailer/src/routes/step3-create-message/editor-styles.css'

// The headless wrapper contains some rather heavy libraries (SWR, Okta, etc.) and we don't want to load them
// in all hosted apps. This should be removed when the headless apps start being used and/or the expensive
// libraries are removed from the headless wrapper.
const DynamicHeadlessWrapper = dynamic(() => import('../src/lib/wrappers/Headless'))

export default function App({ Component, pageProps, router }: AppProps) {
  const isHostedApp = router.route.startsWith('/hosted')

  if (isHostedApp)
    return (
      <HostedWrapper hasApollo>
        <Component {...pageProps} />
      </HostedWrapper>
    )

  const isHeadless = router.route.startsWith('/headless')

  if (isHeadless)
    return (
      <DynamicHeadlessWrapper>
        <Component {...pageProps} />
      </DynamicHeadlessWrapper>
    )

  return <Component {...pageProps} />
}
