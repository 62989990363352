import { ApolloError, isApolloError } from '@apollo/client'

export interface ZendeskClientError {
  status?: number
  statusText?: string
  readyState?: number
  responseText: string
  responseJSON?:
    | {
        error: string
        description?: string
      }
    | { errors: Array<{ code: string; status: string; title: string; detail: string }> }
}

export const isGraphqlError = (e: unknown): e is ApolloError => {
  return e instanceof Error && isApolloError(e)
}

export const isZendeskClientError = (e: unknown): e is ZendeskClientError => {
  if (typeof e === 'object' && e !== null) {
    return 'responseJSON' in e || 'responseText' in e
  }

  return false
}

const isError = (error: unknown): error is Error => {
  if (error instanceof Error) {
    return true
  }

  if (typeof error === 'object' && error !== null && 'message' in error && 'name' in error) {
    return true
  }

  return false
}

export const toError = (e: unknown, message?: string): Error => {
  if (isError(e)) {
    return e
  }

  if (isGraphqlError(e)) {
    return {
      name: e.name,
      stack: e.stack,
      message: e.graphQLErrors.join(','),
    }
  }

  if (isZendeskClientError(e)) {
    const jsonErrorTitle =
      e.responseJSON && 'error' in e.responseJSON ? e.responseJSON.error : e.responseJSON?.errors?.[0]?.title

    const statusText = e.statusText

    return {
      message: e.responseText,
      name: jsonErrorTitle || statusText || 'ZendeskError',
    }
  }

  return {
    message: `${message || 'Unhandled'}: ${JSON.stringify(e)}`,
    name: 'UnhandledError',
  }
}

type ZendeskClientDetailEmailError = {
  responseJSON?: {
    details?: {
      email?: { error: string }[]
    }
  }
}

type ZendeskClientDuplicateEmailError = Required<ZendeskClientDetailEmailError>

export const isZendeskClientDuplicateEmailError = (e: unknown): e is ZendeskClientDuplicateEmailError => {
  if (typeof e === 'object' && e !== null) {
    const err = e as ZendeskClientDetailEmailError
    return Boolean(err.responseJSON?.details?.email?.[0]?.error === 'DuplicateValue')
  }

  return false
}

type ZendeskClientDetailDescriptionError = {
  responseJSON?: {
    details?: {
      base?: { description: string }[]
    }
  }
}

export const isFieldRequiredError = (e: unknown, field: string) => {
  if (typeof e === 'object' && e !== null) {
    const err = e as ZendeskClientDetailDescriptionError
    return Boolean(
      err?.responseJSON?.details?.base &&
        err.responseJSON.details.base.length > 0 &&
        err.responseJSON.details.base[0].description === `${field}: is required when solving a ticket`,
    )
  }

  return false
}
