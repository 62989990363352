import { ThemeProvider } from '@components/Theme'
import { AppName } from '@constants/appName'
import { ApolloWrapper } from '@wrappers/Apollo'
import { BugsnagWrapper } from '@wrappers/Bugsnag'
import { FeatureFlagWrapper } from '@wrappers/FeatureFlag'
import { ZendeskWrapper } from '@wrappers/Zendesk'
import { MotionConfig } from 'framer-motion'
import Script from 'next/script'
import React, { FC, useEffect, useState } from 'react'
import styles from 'src/lib/styles/hosted.module.css'
import { DeveloperToolsWrapper } from 'src/lib/wrappers/developer-tools-wrapper'
import { InitialRouteWrapper } from './InitialRoute'

interface HostedWrapperProps {
  hasApollo?: boolean
  hasZendesk?: boolean
  // Supplying this will override the default behavior of fetching from "northstarToken" app setting
  authToken?: string
  children?: React.ReactNode
}

// The word "logger" in the URL for the "ticket-logger" was causing issues with some offices' firewalls.
// This mapping can be used to map a URL to a specific app name.
const routeToAppName: Partial<Record<string, AppName>> = {
  'create-ticket': 'ticket-logger',
}

export const HostedWrapper: FC<HostedWrapperProps> = ({
  authToken,
  hasApollo = false,
  hasZendesk = true,
  children,
}) => {
  const [appName, setAppName] = useState<AppName>()

  useEffect(() => {
    const getAppName = async () => {
      const zendeskAppName = (await window.client.metadata()).settings.app_name as AppName
      const name = routeToAppName[zendeskAppName] || zendeskAppName

      setAppName(name)
    }
    if (typeof window !== 'undefined') getAppName()
  }, [])

  const ZendeskElement = hasZendesk ? ZendeskWrapper : React.Fragment

  return (
    <>
      <Script strategy='beforeInteractive' src='https://assets.zendesk.com/apps/sdk/2.0/zaf_sdk.js'></Script>
      <Script strategy='beforeInteractive' type='text/javascript' src='/initZAF.js'></Script>

      <div className={styles['hosted-wrapper']}>
        <ZendeskElement appName={appName}>
          <MotionConfig reducedMotion='user'>
            {appName && (
              <BugsnagWrapper appName={appName}>
                <FeatureFlagWrapper appName={appName}>
                  <ApolloWrapper authToken={authToken || ''} isActive={hasApollo}>
                    <InitialRouteWrapper>
                      <DeveloperToolsWrapper>
                        <ThemeProvider>{children}</ThemeProvider>
                      </DeveloperToolsWrapper>
                    </InitialRouteWrapper>
                  </ApolloWrapper>
                </FeatureFlagWrapper>
              </BugsnagWrapper>
            )}
          </MotionConfig>
        </ZendeskElement>
      </div>
    </>
  )
}
