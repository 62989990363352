import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { RetryFunction } from '@apollo/client/link/retry/retryFunction'
import { ZendeskApiClient } from '@client/zendesk-api'
import { isZendeskClientError } from '@monotypes/errors'
import { logger } from '@utils/logger'
import { getFeatureFlagsHeader } from '@wrappers/FeatureFlag'
import { GraphQLError } from 'graphql/error/GraphQLError'

interface IApolloClientOptions {
  northstarToken: string
  zendeskClient: ZendeskApiClient
}

// This is the bare minimum response needed - any functions we do not need are not implemented.
// TODO: this throws a type error during compilation i.e. npm run package ticket-logger
const buildBarebonesResponse = (url: string, graphQLResponse: Record<string, unknown>): Response => ({
  redirected: false,
  body: null,
  type: 'cors',
  bodyUsed: true,
  headers: new Headers(),
  ok: true,
  status: 200,
  statusText: '',
  url,
  json: () => Promise.resolve(graphQLResponse),
  clone: () => {
    throw new Error('clone() is unsupported')
  },
  text: async () => JSON.stringify(graphQLResponse),
  arrayBuffer: () => {
    throw new Error('arrayBuffer() is unsupported')
  },
  blob: () => {
    throw new Error('blob() is unsupported')
  },
  formData: () => {
    throw new Error('formData() is unsupported')
  },
})

/**
 * The sole purpose of this is to obfuscate the {@param auth} token from client-side snooping. This token is used to authenticate
 * GraphQL requests with the server. It is passed to apps during installation as a secure setting meaning it is obfuscated from clients
 * but can only be used with window.client.request calls.
 * Apollo Client uses the native window.fetch under the hood to make GraphQL requests. A custom HttpLink lets us customize the fetch method, in this case
 * we are replacing it with window.client.request and the bare minimum to enable GraphQL requests.
 * @param uri GraphQL endpoint
 * @param userId For logging
 * @param subdomain Used to enforce user permissions, logging, auth
 */
export const httpLink = ({
  uri,
  auth,
  userId,
  subdomain,
  request,
}: {
  uri: string
  auth: string
  userId: number
  subdomain: string
  request: typeof ZendeskApiClient.prototype.request
}) =>
  new HttpLink({
    uri,
    fetch: async (input, init) => {
      // All GraphQL methods should POST
      if (init?.method !== 'POST') {
        throw new Error('Unsupported GraphQL HTTP method')
      }

      try {
        const response = await request<{ errors?: GraphQLError[]; data?: unknown }>({
          source: 'GraphQL',
          logError: false,
          stringify: false,
          // input is the GraphQL endpoint
          url: input as string,
          type: 'POST',
          // init.body is the GraphQL query/mutation
          data: init?.body as string,
          headers: {
            ...init.headers,
            'x-office': subdomain,
            'x-user-id': userId,
            'x-on-behalf-of': userId,
            'x-feature-flags': getFeatureFlagsHeader(),
            Authorization: auth,
          },
          secure: true,
        })

        if (response.errors) {
          logger.error('Error returned from GraphQL call', { error: JSON.stringify(response.errors) })
        }

        return buildBarebonesResponse(input as string, response)
      } catch (error) {
        if (isZendeskClientError(error) && error.status === 401 && error.responseText === 'Unauthorized for proxy') {
          // User is not authorized and likely lost their Zendesk auth session
          // We do not need to log these.
          throw error
        }

        const message = `Error making GraphQL call: ${
          isZendeskClientError(error) ? error.responseText : JSON.stringify(error)
        }`

        logger.error(message, { error, metadata: { query: init?.body } })
        throw error
      }
    },
  })

const getUri = (subdomain: string, zendeskClient: ZendeskApiClient) => {
  return (
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    (zendeskClient.settings['northstar_url'] as string) ||
    (['indigovdev', 'indigovstaging'].includes(subdomain)
      ? 'https://ns-staging.indigov.us/graphql'
      : 'https://ns.indigov.us/graphql')
  )
}

export const createApolloClient = ({ northstarToken, zendeskClient }: IApolloClientOptions) => {
  const { user, subdomain, getCurrentAppDetails } = zendeskClient
  const { name, version } = getCurrentAppDetails()
  const uri = getUri(subdomain, zendeskClient)

  const retryFunction: RetryFunction = (count, _operation, error) => {
    if (count >= 4) {
      logger.error('Received 4 502 Bad Gateway errors in a row. Will not retry.', {
        error,
      })
      return false
    }

    if (!isZendeskClientError(error)) {
      return false
    }

    // We sometimes see the Zendesk Proxy returning a 502 error.
    // In most cases, simply retrying the call will succeed.
    const isBadGatewayError = error.status === 502

    if (isBadGatewayError) {
      logger.info('Received 502 Bad Gateway error. Retrying.', { error })
      return true
    }

    return false
  }

  const IS_DEV = process.env.NODE_ENV !== 'production'

  return new ApolloClient({
    name,
    version,
    cache: new InMemoryCache(),
    connectToDevTools: IS_DEV || process.env.NODE_ENV !== 'production',
    link: from([
      new RetryLink({
        attempts: retryFunction,
      }),
      httpLink({
        uri,
        auth: `Bearer ${northstarToken}`,
        userId: user.id,
        subdomain,
        request: zendeskClient.request,
      }),
    ]),
  })
}
